.dcmodal-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(64, 66, 65, 0.3);
    width: 100vw;
    height: 100vh;
    padding-top: 10px;
}
.dcmodal-container {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    overflow: hidden;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.disturb {
    margin-top: 160px;
    margin-left: 10px;
}
.disturb .dblock h6{
    font-size: 15px;
    font-weight: 100;
}
.disturb .dblock h5{
    font-size: 13px;
    font-weight: 400;
    color: #162082;
}
.dcmdal-header {
    margin-top: 10px;
    color: #162082;
}
.dcmodal {
    border-radius: 10px;
    width: 760px;
    max-height: 760px;
    background-color: rgb(254, 254, 254);
    /* margin-top: 5px; */
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    overflow: scroll;
}

.dcmodal p {
    position: absolute;
    top: 0;
}

.dcmodal img {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.dcmodalbox {
    width: 48%;
    /* height: 315px; */
    background-color: white;
    border-radius: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    margin-top: 20px;
}

.dcmodalbox p {
    padding-left: 20px;
    padding-top: 10px;
    font-weight: 700;
    font-size: 12px;
    width: 200px;
}
.dcmodalbox .leftbar {
    width: 50%;
    height: 68%;
    /* background-color: aqua; */
    position: relative;
    margin-top: 30px;
}

.leftbar img {
    max-width: 90%;
    max-height: 90%;
    margin-left: 100px;
}

.dcmodalbox .rightbar {
    width: 45%;
    height: 100%;
    /* background-color: rgb(0, 255, 34); */
    word-wrap: break-word;
    padding: 10px;
    margin-top: 25px;
}
.rp h5{
    font-weight: 400;
    font-size: 12px;
    color: #162082;
}
.rp h6{
    font-weight: 100;
}
.rp {
    /* background-color: red; */
}
.dblock {
    margin-bottom: 4px;
}