.level1Reject-container{
    width: 356px;
    height: 325px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.level1Reject-header{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5px;
}
.true {
    display: block;
}
.false {
    display: none;
}
.level1Reject-body{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.level1-body2 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #162082;
    margin-top: 33px;
    margin-bottom: 23px;
}
.level1-confirm2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #162082;
    margin: 33px 35px 23px 35px;
    text-align: center;
}
.level1-body3{
    width: 259.77px;
    height: 34.18px;
    border: 0.5px solid #162082;
    border-radius: 5px;
    box-sizing: border-box;
}
.level1Body-dropdown {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    margin-top: 3px;
}
.level1Body-image{
    margin-top: 5px;
}
.level1Body-text{
    color: #162082;
    font-weight: 400;
    font-size: 12px;
}
.level1-body4{
    background: #162082;
    border-radius: 8.91525px;
    font-weight: 700;
    font-size: 14.2644px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 31px;
    width: 234px;
    height: 46px;
    z-index: 0;
    border: none;
}

