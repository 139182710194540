/* body {
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    background-color: #ddd3d3;
  } */
  .profile-view-reactivate-display-details{
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    overflow: hidden;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-view-reactivate {
    position: relative;
    display: block;
    width: 395px;
    height: 328px;      
    /* margin: auto; */
    padding: 8px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    border-radius: 15px;
    background-color: #fff;
  }
   
  .profile-view-reactivate-image {
    margin-top: 23px;
    margin-right: auto;
    margin-left: 164px;
  }
  
  .profile-view-reactivate-image-2 {
    position: absolute;
    left: auto;
    top: 3%;
    right: 2%;
    bottom: auto;
    cursor: pointer;
  }
  
  .close {
      display: none;
  }
  
  .profile-view-reactivate-flexedcont {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .profile-view-reactivate-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 181px;
    height: 53px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    border-radius: 9px;
    background-color: #162082;
  }

  .profile-view-reactivate-w-button {
      color: #fff;
  }

  .profile-view-reactivate-bold-text {
    position: absolute;
    left: auto;
    top: auto;
    right: 0%;
    bottom: 0%;
    margin-right: 35px;
    margin-bottom: 48px;
    color: #162082;
    font-size: 14.76px;
    cursor: pointer;
  }
  
  .profile-view-reactivate-text-block-2 {
    font-size: 17px;
    text-align: center;
    line-height: 25px;
    padding: 0 60px;
    color: #6A616F;
  }

  .profile-view-reactivate-textarea {
      border-radius: 10px;
      padding: 13px;
      background: #FFFFFF;
      border: 1px solid rgba(106, 97, 111, 0.2);
  }