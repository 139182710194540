.u-control-container-general {
    display: flex;
    /* justify-content: space-between; */
}

.user-control-cont{
    width: 100%;
    height: 620px;
    margin-right: 100px;
    height: auto;
    overflow: scroll;
}
.user-control-cont-container{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 85vh;
    overflow-y: scroll;
}
.user-control-head {
    /* width:80%; */
    height: 136px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 40px;
    border-bottom: 1px solid rgb(208, 205, 205);
    margin-bottom: 20px;
}
.user-control-head h5{
    color: rgb(134, 134, 134);
}
.user-control-head > label{
    color: rgb(134, 134, 134);
}
.control-content {
    display: flex;
    justify-content: space-around;
}
.control-left {
    width: 40%;
    height: 100%;
    padding: 0px 50px;
}

.control-right {
    width: 60%;
    height: 90%;
}
.inputbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    height: 47px;
    border-radius: 15px;
    border: 1px solid rgb(175, 173, 173);
    width: 100%;
    padding: 10px;
    padding-top: 25px;
    cursor: pointer;
}
.click-active {
    background-color: rgb(239, 216, 248);
}
.inputboxx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    border-radius: 15px;
}

.inputbox p {
    width: 100%;
}
.headinput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 45%;
    margin-top: 20px;
    padding: 0 8px;
}
.headinput label {
    width: 100%;
    color: gray;
}
.headinput input {
    height: 47px;
    width: 900px;
    border-radius: 10px;
    outline: none;
    border: 1px solid gray;
    padding-left: 5px;
}
.rbox {
    width: 70px;
    height: 30px;
    margin-bottom: 16px;
}
.inputbox2 {
    width: 100%;
    height: 80px;
}
.inputsbox-right-head{
    width: 100%;
    height: 100%;
    display: flex;
}
.togglesright-content{
    width: 90%;
    height: 600px;
    margin: 0 auto;
}
.inputright {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 12px;
}
.inputright > .itwo {
    width: 150px;
    height: 60%;
}
.inputright input{
    height: 100%;
    width: 100%;
    border-radius: 10px;
    outline: none;
    border: 1px solid gray;
}
.select-other {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 5px;
}
.select-other option{
    background: #FFFFFF;
    color: #162082;
}
.inputright > .ione {
    width: 240px;
    height: 60%;
    position: relative;
}
.inputright > .ione img{
    width: 25px;
    position: absolute;
    top: 10px;
    right: 5px;
    cursor: pointer;
}
.ione, .itwo {
    width: 100%;
}
.inputright .itwo {
    position: relative;
}
.inputright .itwo img{
    cursor: pointer;
    width: 25px;
    position: absolute;
    top: 10px;
    right: 5px;
}
.toggleright-box {
    width: 224px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.toggleright-box label{
    margin-right: 20px;
    color: gray;
}
.llist {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 133px;
    height: 40px;
    margin-top: 15px;
}
.llist p {
    width: 100%;
}
.ttbox {
    width: 80px;
    height: 40px;
    color: rgb(93, 89, 89);
}
.tt .ttbox {
    display: flex;
    justify-content: center;
    align-items: center;
}
.tt {
    background: rgba(106, 97, 111, 0.1);
    margin-top: 0;
    height: 60px;
}
.togglesright-content2 {
    
    border: 1px solid rgba(106, 97, 111, 0.3);
    border-radius: 10px;
    margin-top: 10px;
    /* height: 670px; */
}
.togglesright-content2 ul {
    height: auto;
    padding: 0;
    background-color: rgb(255, 255, 255);
    background: #FFFFFF;
    border: 1px solid rgba(106, 97, 111, 0.3);
    border-radius: 10px;
}
.togglesright-content2 .flexlist {
    padding: 0;
    display: flex;
    justify-content: space-between;
    padding-left: 55px;
    padding-right: 200px;
}
.togglelist {
    margin-left: 0;
    padding-right: 0;
    width: 55px;
}
.flexlist p {
    color: grey;
    transition: all 1sec;
    width: 100%;
}
.flexlist {
    background-color: aqua;
}
.flexlist p:hover {
    cursor: pointer;
}
 .bbold {
    font-weight: bold;
 }
 .base-control-btn {
     width: 100%;
     height: 60px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin-top: 40px;
     padding: 0 20px;
 }
 .base-control-btn button:nth-child(1){
    width: 184px;
    height: 53px;
    background-color: #162082;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
 }
 .base-control-btn button:nth-child(2){
    outline: none;
    border: none;
    color: #162082;
    font-size: 1.1em;
    background-color: white;
    cursor: pointer;
 }
 input::-webkit-input-placeholder {/* Chrome/Opera/Safari/Edge */
	/*styles here*/
    padding-left: 20px;
}

input::-ms-input-placeholder { /* Microsoft Edge */
   /*styles here*/
   padding-left: 20px;
}

input:-ms-input-placeholder {/* IE 10+ */
	/*styles here*/
    padding-left: 20px;
}