.cash-provider{
    padding: 47px 12% 36px 12%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 28px;
    text-decoration: none;
}
.cash-provider p, .cash-provider h2{
    color: #21205C;
}
.cash-provider p{
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    width: fit-content;
}
.cash-provider h2{
    font-weight: 600;
    font-size: 48px;
    line-height: 57px;
}