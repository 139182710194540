.gen-cont {
    display: flex;
}
.user-app-content-detail {
    width: 100%;
    height: 100vh;
}
.side2{
    width: 60px;
    height: 100vh;
}
.app-content-cont {
    display: flex;
    justify-content: space-around;
    width: 90%;
    padding-top: 31px;
    margin-left: 50px;
}
.fix {
    margin-left: 100px;
}
.card-cont-2 {
    display: flex;
    justify-content: space-around;
    width: 90%;
    padding-top: 40px;
    margin-left: 100px;
}
.card-2 {
    width: 307px;
    height: 94px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px;
    border: 0.683761px solid #462C94;
    border-radius: 6.83761px;
    cursor: pointer;
}

.app-content-card {
    width: 203px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    border: 0.683761px solid #462C94;
    border-radius: 6.83761px;
    cursor: pointer;
    margin: 10px;
}

.app-content-card:hover {
    background-color: #DDDFF7;
    box-shadow: 0px 8.20513px 19.1453px #A8D5F2;
}
.app-content-record-box h2 {
    color: black;
    font-weight: 600;
    font-size: 32px;
}
.app-content-record-box p{
    width: 100%;
}
.active {
    background-color: #DDDFF7;
    box-shadow: 0px 8.20513px 19.1453px #A8D5F2;
    font-weight: 700;
    color: #162082;
    font-size: 15px;
}
.active p {
    color: #162082;
    width: 90%;
}

.display-deactivate-control {
    position: absolute;
    margin-left: -420px;
    margin-top: -250px;
  }
