@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-spinner {
    width: 35px;
    height: 35px;
    border: 7px solid #f3f3f3;
    border-top: 15px solid #162082;
    border-radius: 50%;
    animation: spinner 1.0s linear infinite;
  }
  
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
  }
