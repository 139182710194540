.add-new-user-container {
  /* width: 676px;
    margin: 20px auto;
    padding: 25px 96px;
    background-color: #FFFFFF; */
  position: relative;
  background: #fff;
  width: 678px;
  height: 678px;
  margin: 30px auto;
  border-radius: 20px;
}
.user-select option {
  /* margin: 40px; */
  background-color: white !important;
  /* color: rgb(151, 229, 48);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); */
}

.user-error-span {
  color: #ff0000;
  margin: 0px;
  margin-top: -30px;
  margin-bottom: 5px;
}
.top-error-span {
  color: #ff0000;
  margin: 0px;
  margin-top: -30px;
  margin-bottom: 5px;
  margin-left: 100px;
}
.add-new-user-container p {
  /* padding: 30px; */
  /* display: inline; */
  margin-top: -20px;
  align-items: center;
}

.canc-icon {
  margin-left: 630px;
  align-items: center;
  cursor: pointer;
}

.canc-icon img {
  margin-top: 50px;
  margin-bottom: -20px;
}

.add-new-user-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 40px 90px;
}
.add-user-btn {
  background: #162082;
  border-radius: 10px;
  height: 54px;
  color: #ffffff;
  cursor: pointer;
}
.add-user-cancel {
  border: none;
}

.add-user-btn-sec {
  display: flex;
  margin: auto;
}
.add-user-btn-sec button {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  width: 184px;
  border: none;
  cursor: pointer;
  margin-right: 15px;
}
.add-new-user-content input,
.add-new-user-content select {
  background: #ffffff;
  border: 1px solid rgba(106, 97, 111, 0.2);
  border-radius: 10px;
  height: 54px;
  padding: 10px;
  margin-bottom: 38px;
}
.add-new-user-content input {
  margin-bottom: 28px;
}

.add-new-user-content label {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #6a616f;
  margin-bottom: 7px;
}

.display-new-user-details {
  width: 1615px;
  height: 1300px;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0px;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  z-index: 1;
}
