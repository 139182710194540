.app-notification-notification {
  position: relative;
  display: block;
  width: 395px;
  height: 312px;
  margin: 230px  auto;
  padding: 8px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-radius: 15px;
  background-color: #fff;
}

.closebox {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background-color: #162082;
}

.image {
  margin-top: 23px;
  margin-right: auto;
  margin-left: 154px;
}

.app-notification-notification-image-2 {
  position: absolute;
  left: auto;
  top: 3%;
  right: 2%;
  bottom: auto;
  cursor: pointer;
}

.app-notification-notification-image2 {
  margin-top: -46px;
  margin-left: 10px;
}

.deactivate-close {
    display: none;
}
.app-notification-notification-text-block {
  position: relative;
  left: 10%;
  top: auto;
  right: 0%;
  bottom: 32%;
  font-family: 'Droid Sans', sans-serif;
}

.app-notification-notification-flexedcont {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.app-notification-notification-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 171px;
  height: 46px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 9px;
  background-color: #162082;
  text-decoration: none;
}
.app-notification-notification-w-button {
    color: #fff;
}
/* .bold-text {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  margin-right: 35px;
  margin-bottom: 43px;
  color: #162082;
  font-size: 14.76px;
  cursor: pointer;
} */

.app-notification-notification-text-block-2 {
  font-size: 17px;
  text-align: center;
  line-height: 25px;
  padding: 20px 50px;
}

.app-notification-display-details {
  width: 1900px;
  height: 1300px;
  align-items: center;
  position: fixed;
  z-index: 1500;
  /* background: rgba(0, 0, 0, 0.3); */
}

.app-notification-display-detailss {
  width: 100vw;
  height: 100vh;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0px;
  background: rgba(0, 0, 0, 0.30);
  overflow: hidden;
  z-index: 10000000;
  justify-content: center;
  display: flex;
}