.uploadDoc-container {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    overflow: hidden;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close {
    display: none;
}
.uploadDoc-card-container {
    background: #FFFFFF;
    width: 850px;
    height: 95vh;
    padding: 10px;
    overflow: scroll;
}

.uploadDoc-card-header {
    display: flex;
    margin-top: 10px;

}

.uploadDoc-card-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin: auto;
    width: 250px;
    color: #462C94;
}

.uploadDoc-card-body {
    margin-top: 106px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uploadDoc-card-body-label {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #6A616F;
    margin-top: 10px;
}

.uploadDoc-card-body-input {
    background: #FFFFFF;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
    padding: 5px;
    width: 310px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.input-placeholder {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #6A616F;
    margin-top: 15px;
    margin-left: 5px;
    width: 127px;
    height: 12px;
}

.uploadDoc-card-content{
    display: flex;
}

.uploadDoc-card-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 134px;
    margin-bottom: 156px;
}

.uploadDoc-card-footer-botton {
    background: #162082;
    border-radius: 8.91525px;
    color: #FFFFFF;
    padding: 10px;
    width: 171px;
    height: 46px;
    font-weight: 700;
    cursor: pointer;
}

.uploadDoc-optinValue {
    font-weight: 400;
    font-size: 18px;
    width: 250px;
    margin-left: 8px;
}
