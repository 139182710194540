.containDash{
    display: flex;;
}
.vcont {
    display: flex;
    margin-left: 80px;
    margin-top: 50px;
    /* justify-content: space-between; */
}
.view---top{
    width: 100%;
}

.control {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: scroll;
}
.kyc-approve-tick {
    cursor: pointer;
}
.kyc-view-rejected {
    width: 100px;
    height: 32px;
    margin-right: 3px;
    background: #FC3131;
    border-radius: 10px;
    color: white;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14.2644px;
    border: none;
}
.t1{
    margin-bottom: 15px;
}
.t1 h2 {
    font-weight: 400;
}
.t1 p{
    width: 200px;
}
.txthouse {
    width: 90%;
}
.picright {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    width: 446px;
}
.kyc-view-submit-btn{
    width: 172px;
    height: 46px;
    background: #162082;
    border-radius: 10px;
    border: none;
    color: white;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14.2644px;
}
.kyc-view-approved{
    width: 100px;
    height: 32px;

    background: #089E17;
    border-radius: 10px;
    color: white;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14.2644px;
    border: none;
}
.kyc-view-cancel-btn{
    width: 32px;
    height: 32px;
    color: white;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14.2644px;
    background: #FC3131;
    border-radius: 10px;
    border: none;
}
.up {
    display: flex;
    justify-content: space-between;
    margin-bottom: 84px;
}
.upbox {
    width: 162px;
}
.upbox img {
    transition: 0.7s;
}
.upbox img:hover {
    transform: scale(1.1);
}
.down {
    display: flex;
     justify-content: space-between;
     margin-bottom: 60px;
}
.chk {
    display: flex;
    justify-content: space-between;
}
.bvn-input-box {
    margin-bottom: 20px;
}
.compare-disable  {
    background: #7b7b80ce;

}

.chk2 {
    margin-top: 10px;
    padding:0 5px;
    display: flex;
    justify-content: space-between;
}

.chk3 {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.cdiv {
    width: 24px;
    height: 24px;
} 
.chk p {
    width: 100%;
}
.caret {
    width: 3px;
}
.compare-img{
margin-left: 150px;
}
.picright img {
    width: 162px;
}
.downbox img {
    width: 250px;
    height: 140px;
}
.t1 h2 {
    font-size: 1.2rem;
    color: #162082;
}