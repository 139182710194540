.profile-search-wrapper {
    display: flex;
    flex-direction: column;
}
.profile-search-cont {
    margin-top: 190px;
    margin-bottom: 50px;
    width: auto;
}
.support-search-text{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #162082;
}
.profile-search-cont h3 {
    margin-bottom: 30px;
    color: #162082;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
}

.profile-search-cont input {
    width: 594px;
    height: 65px;
    padding: 10px 10px;
    margin-right: 35px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    outline: none;
}

.profile-search-cont.input {
    width: 594px;
    height: 65px;
    padding: 10px 10px;
    margin-right: 35px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    outline: none;
    display: flex;
}

.profile-search-cont input {
    width: 36%;
}

.profile-search-cont .support-btn {
    width: 171px;
    height: 46px;
    background: #162082;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    border: none;
}
