.rolesP-table-content table {
    font-family: arial, sans-serif;
    border-collapse: separate;
    border-spacing: 0 7px;
    width: 100%;
    /* padding: 0px 80px; */
  }
  .users-pagination {
    padding-top: 0px;
    padding-bottom: 0px;
    width: 90%;
  }
.rolesP-table-content {
    margin-left: 20px;
    max-height: 45vh;
    overflow-y: scroll;
    max-width: 100%;
    margin: 0px 85px;
 
}
.rolesP-table td {
    text-align: left;
    padding-left: 40px;
}
.rolesP-cont {
    width: 70%;
    height: 50px;
    margin: 70px auto 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rolesP-kscont img {
    width: 20px;
    position: absolute;
    right:15px;
    top: 27px;
}

.rolesP-kscont {
    align-items: center;
    margin-left: 635px;
    margin-bottom: 60px;
    position: absolute;
}

.rolesP-item-tr {
    background: #dddff6;
 }

 .rolesP-btn {
   width: 33px;
   height: 33px;
   background-color: #162082;
   border: none;
   outline: none;
   border-radius: 100%;
   color: white;
   font-weight: 700;
   cursor: pointer;
}
 
 .rolesP-btn-primary {
    display: flex;
    align-items: center;
    padding: 0px 30px;
    margin-left: -73px;
    border: none;
    background: #162082;
    color: #fff;
    border-radius: 10px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
 }

 .rolesP-btn-primary img {
     padding-right: 10px;
 }