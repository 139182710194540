.supportDiv {
    width: 100%;
    margin-top: 50px;
}
.support-ticket-pagination-bar {
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
}
.support-ticket-supportDiv {
    width:  100%;
    margin-top: 180px;
}
.pending-approval-supportDiv {
    width: 100%;
    margin-top: 180px;
}
.support-ticket-kscont {
    display: flex;
    align-items: center;
    /* margin-top: -200px; */
    /* margin-bottom: 30px; */
    /* position: relative; */
}
.search-records-img{
    margin-left: -20px;
    margin-top: 20px;
}
.create-ticket {
    width: 171px;
    height: 46px;
    background: #162082;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    margin-right: 40px;
    margin-top: 20px;
}
.support-search-cont{
    /* margin-top: -10px; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 60px;
    margin: 0 auto;
}

.support-table {
    /* border-collapse: collapse; */
    border-collapse: separate;
    border-spacing: 1.2em;
    /* margin: 70px auto; */
    /* margin-top: -50px; */
    margin: auto auto;
    font-size: 0.9em;
    min-width: 400px;
    width: 100%;
}

.support-table th {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #162082;
    line-height: 0%;
    margin-bottom: 5px;
}
.support-table td, th {
    text-align: left;
}
.support-ticket-button {
    width: 28px;
    height: 28px;
    background-color: darkblue;
    border-radius: 100%;
    color: white;
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
}
.support-table-content{
    width: 1120px;
    margin: auto;
}
.profile-search-support-table-content {
    width: 100%;
    max-height: 32vh;
    overflow: scroll;
}
.profile-search-support-table-content tr {
    margin: 0px;
}
/* .support-table-tr {
    border-radius: 218px !important;
    background: #dddff6;
    border-collapse: separate;
} */
.table-content{
    width: 100%;
    margin: auto;
    max-height: 50vh;
    overflow-x: scroll;
    margin-top: 25px;
}
.inputbar {
    width: 100%;
    margin-right: -100px;
    /* 
    height: 139px;
    /* margin: 90px -20px; */
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.inputbar img {
    width: 20px;
    position: absolute;
    top: 497px;
    
}

.vendmodcont {
    position: absolute;
    top: 965px;
    left: 369px;
}