.view-support-ticket-modal {
    background-color: #ffffff;
    width: 760px;
    margin: 0px auto;
    height: 700px;
    border-radius: 10px;
    padding: 10px;
    display: block;
    z-index: 5;

}
.close-modal {
    display: none;
}
.view-support-ticket-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1500;
}
.view-support-ticket-header {
    display: flex;
    width: 600px;
    margin-left: 700px;
    cursor: pointer;
}
.view-support-ticket-right{
    font-weight: 600;
    font-size: 18px;
    color: #6A616F;
    line-height: 28px;
}
.view-support-ticket-body p{
    width: 515px;
    padding: 8px;
    margin: 0px 10px;
    background: #FFFFFF;
}
.text-box-footer {
    position: absolute;
    bottom: 0;
}
.view-support-ticket-body-1-p {
    height: 85%;
    overflow: auto;
}
.view-support-ticket-body-1 {
    width: 545px;
    height: 223px;
    margin: 0px 60px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    position: relative;
}
.view-support-ticket-body-2 {
    width: 254px;
}

.view-support-ticket-footer {
    margin-left: 10%;
    margin-top: 20px;
}
.view-support-ticket-footer-button {
    background: #162082;
    border-radius: 10px;
    padding: 10px 20px;
    color: white;
    font-weight: 700;
    font-size: 18px;
    width: 125px;
    text-decoration: none;
    border: none;
}

.view-support-ticket-footer-button-2 {
    background: none;
    border: none;
    padding: 10px;
    color: #162082;
    font-weight: 700;
    font-size: 18px;
    width: 125px;
    text-decoration: none;
}
.view-support-ticket-umCont {
    padding-top: 30px;
    width: 100vw;
    z-index: 1200;
}

.view-support-ticket-body-text {
    display: flex!important;
    margin: 10px 30px 0px!important;
}

.view-support-ticket-inputs h5 {
    margin-top: 7px!important;
    margin-left: 30px!important;
    margin-bottom: 0px!important;
    color: #6A616F!important;
    text-align: left!important;
}

.view-support-ticket-inputs p {
    width: 200px!important;
    margin-left: 20px!important;
    padding: 10px!important;
    background: #FFFFFF!important;
    border-radius: 10px!important;
    color: #6A616F!important;
}

.view-support-ticket-inputs-2 h5 {
    margin-top: 7px!important;
    margin-left: 190px!important;
    margin-bottom: 0px!important;
    color: #6A616F!important;
}

.view-support-ticket-inputs-2 p {
    width: 200px!important;
    margin-left: 180px!important;
    padding: 10px!important;
    background: #FFFFFF!important;
    border-radius: 10px!important;
    color: #6A616F!important;
}

.inner-p {
    display: flex!important;
    justify-content: space-between!important;
    width: 700px!important;
}

.inner-para-1 {
    background: rgba(196, 196, 196, 0.25) !important;
    border-radius: 19px !important;
    margin-bottom: -20px !important;
    padding: 4px 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 0% !important;
    cursor: pointer;
}

.inner-para{
    width: 400px !important;
    text-decoration: underline !important;
    color: #6A616F !important;
    background: rgba(196, 196, 196, 0.25) !important;
    border-radius: 19px !important;
    margin-left: 370px !important;
    padding: 4px 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 0% !important;
}

.view-desc {
    width: 150px !important;
    color: #6A616F !important;
    font-size: 20px !important;
    font-weight: bold !important;
    margin-left: 49px !important;
    margin-bottom: 10px !important;
}

.view-title-con {
    margin-top: 20px !important;
    margin-bottom: 30px !important;
    margin-left: 50px !important;
    color: #6A616F !important;
}
.view-title-con h3 {
    display: block;
    font-size: 1.17em;
    margin-bottom: -1.5px;
    font-weight: bold;
}
.view-support-ticket-inputs h5 {
    display: block;
    font-size: 0.83em;
    margin-bottom: -1.5px;
    font-weight: bold;
}
.view-support-ticket-inputs-2 h5 {
    display: block;
    font-size: 0.83em;
    margin-bottom: -1.5px;
    font-weight: bold;
}