.faq-section{
    display: flex;
}
.faq-container {
    margin-left: 20px;
}
.faq-notification-wrapper {
    height: 100vh;
    overflow: auto;
}
.faq-header h2{
    color: #6A616F;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}
.faq-app-top {
 
}
.faq-content-1{

}
.app-nav {
}

.faq-title h4, .faq-message h4, .faq-content-1 h5, .faq-content-2 h5 {
    font-size: 14px;
    line-height: 19px;
    color: #6A616F;

}

.faq-title, .faq-message  {
    margin-top: 50px;
}
.faq-title input[type=text]{
    width: 450px;
    padding: 15px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
}

.faq-message h4 {
}

.faq-message textarea{
    width: 500px;
    padding: 15px;
    height: 150px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
}

.faq-content-1 {
}

.faq-cont-1-input {
    display: flex;
    width: 350px;
    background-color: red;
}

.faq-content-2 {
    display: flex;
    margin-top: 50px;
}

.faq-footer-3  {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
.faq-footer-botton{
    background: #162082;
    border-radius: 8.91525px;
    color: #FFFFFF;
    padding: 10px 40px;
    width: 200px;
    height: 46px;
    font-weight: 700;
    margin-right: 28px;
    cursor: pointer;
    border: none;
}
.faq-footer-close{
    font-weight: 700;
    font-size: 14.2644px;
    line-height: 17px;
    color: #162082;
    background: #FFFFFF;
    border: 0;
    cursor: pointer;
}


