.re-ticketContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    /* left: 0; */
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1500;
}
.house {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 20px;
}
.house h6 {
    font-size: 20px;
    line-height: 23.7px;
    color: #6a616f
}
.re-ticketCont {
    width: 769px;
    height: 500px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 20px;
    padding-left: 50px;
}
.tickethead{
    display: flex;
    justify-content: space-between;
    position: relative;
    border: 1px solid rgba(106, 97, 111, 0.2);
}
.retextdiv{
    width: 600px;
    font-size: 14px;
    line-height: 16.60px;
    font-weight: 100;
    margin-bottom: 20px;
}
.retextdiv span{
    font-size: 1rem;
    display: inline;
    font-weight: 600;
}
.retextdiv h6{
    font-weight: 100;
    font-size: 14px;
    color: #6a616f;
}
.tickethead p{
 width: 600px;
}
.firstinputbox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    height: 80px;
}
.wrapper-class input[type="radio"] {
    width: 15px;
}
.inpbox {
    height: 60px;
    width: 380px;
    position: relative;
    display: flex;
    justify-content: flex-end;
}
.inpbox img {
    position: absolute;
    top: 20px;
    width: 20px;
    right: 8px;
}
.house img:hover {
    cursor: pointer;
}
.inpbox2 {
    width: 320px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: 80px;
}
.inpbox2 img {
    position: absolute;
    top: 15px;
    width: 20px;
    right: 6px;
}
.wrapper-class label {
    display: inline;
    margin-left: 5px;
    margin-right: 40px;
}
.secondinputbox {
    width: 100%;
    height: 132px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.reticketbtngtoup {
    width: 50%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.reticketbtngtoup button {
    background: #162082;
    border-radius: 10px;
    width: 168px;
    height: 56px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border: none;
}
.reticketbtngtoup button:nth-child(2) {
    background-color: white;
    color: #162082;
    outline: none;
}
.layer-2 h6 {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 100;
}