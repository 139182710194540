.document-status-container{
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    overflow: hidden;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.document-status-content{
    width: 651px;
    background-color: white;
    padding: 0 35px;
    margin: auto;
    border-radius: 15px;
}
.document-status-title{
    margin: auto;
    padding-top: 48px;
    margin-bottom: 38px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

color: #162082;
}
.document-status-cancel{
    margin-top: 19px;
    float: right;
    cursor: pointer;
}
.close {
    display: "none"
}
.document-status-table{
    width: 100%;
    border-collapse: separate;
}

.document-status-table button{
    color: white;
    border: none;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 0%;
}
.document-status-approved button{
    width: 101px;
    height: 27px;
    background: #089E17;
    border-radius: 15px;
}
.document-status-reject button{
    width: 101px;
    height: 27px;
    background: #FC3131;
    border-radius: 15px;
}
.document-status-nil button{
    width: 101px;
    height: 27px;
    background: #162082;
    border-radius: 15px;
}
.document-status-pending button{
    width: 101px;
    height: 27px;
    background: #d59b1e;
    border-radius: 15px;
}

.document-status-button-section{
    width: 100%;
    display: flex;
    align-items: center;
}
.document-status-close{
    width: 176px;
    height: 46px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14.2644px;
    line-height: 17px;
    text-align: center;
    background: #162082;
    border-radius: 8.91525px;
    color: #FFFFFF;
    margin: 38px auto;
    border: none;
    cursor: pointer;
}