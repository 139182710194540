.login-history-container{
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    overflow: hidden;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-history-content{
    width: 921px;
    background-color: white;
    padding: 0 35px;
    margin: auto;
    border-radius:3px;
}
.login-history-title{
    margin: auto;
    padding-top: 48px;
    margin-bottom: 38px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #162082;
}
.history-items{
    width: 100%;
    border-radius: 218px !important;
    background: #dddff6;
}
.login-history-table-wrapper {
    max-height: 400px;
    overflow: scroll;
}
.user-login-history-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 3px;
}
.login-history-cancel{
    margin-top: 19px;
    float: right;
}
.login-history-table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 3px;
}
.login-history-table tr{
    text-align: center;
}

.document-status-table button{
    color: white;
    border: none;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 0%;
}
.login-history-button-section{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.login-history-close{
    width: 176px;
    height: 46px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14.2644px;
    line-height: 17px;
    text-align: center;
    background: #162082;
    border-radius: 8.91525px;
    color: #FFFFFF;
    margin-top: 38px ;
    margin-bottom: 38px ;
    border: none;
}