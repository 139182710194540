.new-article-header h2{
    font-size: 16px;
    color: #6A616F;
}
.article-notification{
    display: flex ;
}
.article-container{
    margin-left: 30px;
}
.articles-notification-wrapper{
    height: 100vh;
    overflow: auto;
}
.new-article-title h5, .new-article-message h4, .article-cont-1 h5,.new-article-content-2 h5 {
    /* margin: 20px 0px 7px 0px; */
    font-size: 12px;
    margin-top: 40px;
    color: #6A616F;
}

.new-article-title input{
    width: 450px;
    padding: 15px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
}

.new-article-message h4 {
    margin: 50px 0px 7px 0px;
}

.new-article-message textarea{
    width: 500px;
    padding: 15px;
    height: 150px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
}

.new-article-content-1 {
    display: flex;
    margin: 50px 0px 7px 0px;
}

.article-cont-1-input {
    display: flex;
    width: 246px;
    height: 56px;
    padding: 16px 13px;
    margin-top: 7px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;

}
.article-input-placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #6A616F;
    height: 12px;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 15px;
}

.app-cont-3 p  {
    display: inline;
}

.new-article-content-2 {
    display: flex;
    margin: 20px 0px 27px 0px;
}

.article-cont-3 {
    width: 246px;
    height: 56px;
    padding: 16px 13px;
    margin-top: 7px;
    margin-right: 50px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
}

.article-cont-3 p {
    display: inline;
}

.article-cont-3 img {
    margin-bottom: -5px;
    margin-left: 80px;
}

.new-article-footer-3 {
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 30px;

}
.new-article-footer-botton{
    background: #162082;
    border-radius: 8.91525px;
    color: #FFFFFF;
    padding: 10px 40px;
    width: 200px;
    height: 46px;
    font-weight: 700;
    margin-right: 28px;
    cursor: pointer;
    border: none;
}
.new-article-footer-close{
    font-weight: 700;
    font-size: 14.2644px;
    line-height: 17px;
    color: #162082;
    background: #FFFFFF;
    border: 0;
    cursor: pointer;
}


