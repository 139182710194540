.landing-logo {
    font-style: normal;
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
  }
  .zoja {
    color: #162082;
  }
  .pay {
    color: #22eed3;
  }