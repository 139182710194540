
.gen-cont {
    display: flex;
}
.side2{
    width: 60px;
    height: 100vh;
    background-color: red;
}
.kyc-card-cont {
    display: flex;
    /* justify-content: space-around; */
    width: 100%;
    padding-top: 15px;
    margin-left: 20px;
    overflow: scroll;
}
.kyc-card-cont p{
    width: 100px;
}
.kyc-card {
    width: 180px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    border: 0.683761px solid #462C94;
    border-radius: 6.83761px;
    cursor: pointer;
    margin: 10px;
}

.kyc-card:hover {
    background-color: #DDDFF7;
    box-shadow: 0px 8.20513px 19.1453px #A8D5F2;
}
.kyc-record-box {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.kyc-record-box h2 {
    color: black;
    font-weight: 600;
    font-size: 26px;
    margin: 0;
}
.kyc-record-box p{
    width: 100%;
}
.kyc-active {
    background-color: #DDDFF7;
    box-shadow: 0px 8.20513px 19.1453px #A8D5F2;
}