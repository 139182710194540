* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .welcome-header {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    padding-top: 30px;
    color: #162082;
    text-align: center;
  }
  
  p {
    width: 29%;
    color: #162082;
    margin-right: 0;
  }
  
  .welcome-input-section input {
    outline: none;
    border: none;
    width: 100%;
    padding: 3%;
  }
  
.welcome-log-container {
  display: flex;
  width: fit-content;
  flex-direction: column;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}
  
  .welcome-login-container {
    height: 501px;
    width: 501px;
    margin: 17px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }
  
  .welcome-login-contents {
    width: 90%;
    margin: 5% 5%;
    padding: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .welcome-pass {
    position: absolute;
    background-color: #fff;
    color: #222B88;
    top: -17px;
    z-index: 10000;
    margin: 0 15px;
    padding: 2%;
  }
  
  .welcome-span {
    position: absolute;
    background: #162082;
    width: 44px;
    height: 48px;
    border-radius: 10px;
    margin-left: 85%;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 20px;
  }
  
  .eye-icon {
    position: absolute;
    margin-left: 50px;
    color: #162082;
    margin-left: 250px;
  }
  
  .welcome-email-section {
    display: flex;
    position: relative;
    margin: 0 10%;
    bottom: 30px; 
  }

  .welcome-name-section {
    margin-bottom: 20px;
    margin-top: -10px;
    bottom: 50px; 

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #162082;
    text-align: center;
  }
  .error-span {
    color: #ff0000;
    margin: 0 50px;
  }
  
  .welcome-input-section{
    width: 80%;
    display: flex;
    position: relative;
    padding: 1%;
    margin: 0 10px;
    align-items: center;
    border: 3px solid #222B88;
    border-radius: 15px;
    top: 20px;
    justify-content: space-between; 
  }

  .btn {
      padding: 10px;
      border-radius: 50px;
      background: #fff;
      color: #162082;
      border: none;
      cursor: pointer;
      text-decoration: none;
  }

  .img {
      margin: 7px -5px;
      cursor: pointer;
  }

  .new-login {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(137.43deg, rgba(255, 255, 255, 0.5) 3.89%, 
      rgba(255, 255, 255, 0.2) 100%);
    margin: 40px auto;
    width: 231px;
    height: 60px;
    border-radius: 50px;
  }

  .avata {
      width: 40px;
      background: #fff;
      border-radius: 50px;
  }