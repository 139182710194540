.uploadDoc-modal {
    background: #ffffff;
    width: 300px;
    height: 220px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.uploadDoc-wrapper {
    position: absolute;
    z-index: 1000000;
    margin-left: 380px;
    border-radius: 15px;
    margin-top: 215px;
    /* margin-right: 70px; */
}
.true {
    display: flex;
}
.false {
    display: none;
}

.uploadDoc-modal-content {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #162082;
    padding: 5px;
    margin-left: 5px;
    cursor: pointer;
    z-index: 1000;
}
