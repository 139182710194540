.profile-review-con {
    display: flex;
    justify-content: space-evenly;
    /* padding-left: 50px; */
    width: 100%;
    height: 88vh;
    overflow-y: scroll;
}
.profile-review-modals {
    width: 100vw;
    height: 100vh;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.27);
    overflow: hidden;
    z-index: 1;
}
.profile-preview-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.profile-top {
    width: calc(100% - 224px);
    margin-left: 40px;
    margin-right: 20px;
    height: 100vh;
}
.profile-preview-body {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
}
.profile-nav {
    /* position: absolute; */
    /* width: 20%; */
}

.customer-app {
    width: 70%;
    /* height: 511px; */
    /* margin: 0px 340px; */
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
}

.customer-app  h4{
    margin-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #6A616F;
    color: #6A616F;
    /* margin-left: 340px; */
    /* margin-right: -300px; */
}

.customer {
    display: inline-grid;
    grid-template-columns: auto auto auto;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 40px;
}
.app-activity {
    display: inline-grid;
    grid-template-columns: auto auto auto;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 40px;
}

.grid-item {
    padding: 70px 50px 0px 0px;
}

.customer h6, .customer p {
    width: auto;
    height: 2px;
}

.customer h6, .app-activity h6 {
    color: #162082;
}

.app-activity h6 {
    padding-top: -130px;
}

.app-activity h6, .app-activity p {
   width: 260px;
   height: 3px;
}
.support-func-cont {
    width: 20%;
}

.support-func-cont h4 {
    margin-top: 30px;
    margin-bottom: 10px;
    
}

.support-func-con {
    background: #162082;
    border-radius: 10px;
    width: 241px;
    height: 462px;
    
}

.support-func {
    padding: 20px 20px;
}

.support-func button {
    display: block;
    width: 100%;
    height: 50px;
    margin-top: 10px;
    background: #7780DE;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
}

.support-func .green-btn {
    background: #089E17;
}
.support-func .red-btn {
    background: #cf3308;
}