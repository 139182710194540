.kyc-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 68px;
}

.kyc-para {
    color: #162082;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
}