.email-app-notification-container {
    padding-left: 350px;
    width: 840px;
    height: 511px;
}
.email-notification-wrapper {
    height: 96vh;
    overflow-y: scroll;
}
.app-top {
    margin: -18px 0px 0px 240px;
}

.app-nav {
    position: absolute;
}

.email-app-notification-title h4{
    margin: 70px 0px 7px 0px;
}

.new-app-notification-container {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #6A616F;

}


.email-app-notification-title input{
    width: 100%;
    padding: 15px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
}

.email-app-notification-message h4 {
    margin: 20px 0px 7px 0px;
}

.email-app-notification-message textarea{
    width: 100%;
    padding: 15px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
}

.email-app-notification-content-1 {
    display: flex;
    /* justify-content: space-around; */
    margin: 20px 0px 7px 0px;
}

.email-app-cont-1-input {
    width: 246px;
    height: 56px;
    padding: 16px 13px;
    margin-top: 7px;
    margin-right: 50px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;

}
.email-app-input-placeholder {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #6A616F;
    margin-top: -20px;
    margin-left: 25px;
    width: 127px;
    height: 12px;
    text-decoration: underline;
    cursor: pointer;
}

.email-app-cont-2 {
    width: 240px;
    height: 56px;
    padding: 19px 50px;
    margin-top: -13px;
    margin-right: 50px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
}

.email-app-cont-2 p, .email-app-cont-3 p  {
    display: inline;
}

.email-app-cont-2 input {
   border: none;
   padding: 8px 10px;
   margin-top: -20px;
   outline: none;
}
.embed-link img {
    margin-bottom: -35px;
    margin-left: 10px;
}

.email-app-notification-content-2 {
    display: flex;
    margin: 20px 0px 27px 0px;
}

.email-app-cont-3 {
    width: 246px;
    height: 56px;
    padding: 16px 13px;
    margin-top: 7px;
    margin-right: 50px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
}

.email-app-cont-3 p {
    display: inline;
}

.email-app-cont-3 img {
    margin-bottom: -5px;
    margin-left: 80px;
}

.email-app-cont-4 {
    width: 246px;
    height: 56px;
    padding: 16px 13px;
    margin-top: 7px;
    margin-right: 50px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
}
.email-app-cont-4 input[type=text]{
    border: none;
}

.email-app-cont-4 img {
    margin-bottom: -5px;
    margin-left: 50px;
}

.email-app-cont h5{
    width: 100%;
    display: block;
    padding: 10px 10px;
}

/* .app-conts {
    width: 20%;
    padding: 10px 10px;
    margin-top: -17px;
    margin-right: 150px;
    margin-left: 10px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
} */

.email-app-cont img {
    margin-left: 20px;
    margin-top: -10px;
    color: #6A616F;
}

.email-app-conts-2 {
    width: 60%;
    padding: 10px 10px;
    margin-top: 5px;
    margin-right: 30px;
    margin-left: -10px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
}

.email-app-dot {
    margin-top: 45px;
    margin-right: 20px;
    margin-left: -140px;
}

.email-app-notification-footer h5{
    margin-bottom: 20px;
}
.app-input-label{
    margin-right: 20px;
}

.email-app-notification-footer-2 {
    width: 30%;
    padding: 10px 10px;
    margin-top: 17px;
    margin-right: 150px;
    margin-left: 10px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
}

.email-app-notification-footer-2 div {
    margin-left: 40px;
    margin-top: -20px;
    text-decoration: underline;
    cursor: pointer;
}

.email-app-notification-footer-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 122px;
    margin-bottom: 70px;
    margin-left: -150px;
}
.email-app-notification-footer-botton{
    background: #162082;
    border-radius: 8.91525px;
    color: #FFFFFF;
    padding: 10px;
    width: 250px;
    height: 46px;
    font-weight: 700;
    margin-right: 28px;
    cursor: pointer;
    border: none;
}
.email-app-notification-footer-close{
    font-weight: 700;
    font-size: 14.2644px;
    line-height: 17px;
    color: #162082;
    background: #FFFFFF;
    border: 0;
    cursor: pointer;
}

.email-app-wrapper-class{
    margin-left: -195px;
}

.email-app-wrapper-class-1 label, .email-app-wrapper-class label {
    display: inline;
    margin-left: 5px;
    margin-right: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.email-app-notification-footer-1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    height: 40px;
    margin-left: -130px;
}
