.uploadDocMain-container{
    margin-top: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.uploadDoc-card-body-input.date{
    width: 170px;
    padding: 10px;
}
.uploadDocMain-content {
    display: flex;
}

.uploadDocMain-input {
    background: #FFFFFF;
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
    padding: 5px;
    width: 310px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
}

.uploadDocMain-label {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #6A616F;
    margin-top: 10px;
}

.uploadDocMain-divider {
    margin-top: "15px";
    width:"760px" ;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uplaodDocMain-details {
    margin-top: 28px;
    margin-left: 155px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #6A616F;
}
.uploadDocMain-detailsInput{
    margin-left: 155px;
    margin-top: 30px;
}
.detailsInput-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A616F;
    margin-bottom: 5px;
}
.detailsInput-input{
    border: 1px solid rgba(106, 97, 111, 0.2);
    border-radius: 10px;
    padding: 15px;
    width: 310px;
    height: 45px;
    box-sizing: border-box;
}
.detailsInput-date{
    display: flex;
    margin-top: 30px;
    justify-content: flex-start;
}
.uploadDoc-expiryDate{
    display: flex;
}
.uploadDocMain-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 10px;
}
.uploadDocMain-footer-botton{
    background: #162082;
    border-radius: 8.91525px;
    color: #FFFFFF;
    padding: 10px;
    width: 171px;
    height: 46px;
    font-weight: 700;
    margin-right: 28px;
    cursor: pointer;
}
.uploadDocMain-close{
    font-weight: 700;
    font-size: 14.2644px;
    line-height: 17px;
    color: #162082;
    background: #FFFFFF;
    border: 0;
    cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    display: block;
}
