.login-containers {
    display: flex;
    width: fit-content;
    flex-direction: column;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
}

.login-header {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 46px;
    color: #162082;
    text-align: center;
}

.login-container {
    width: 531px;
    margin: 17px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding-top: 50px;
}

.login-form-section {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    
}

.login-contents{
    width: 65%;
}

.login-input-section{
    display: flex;
    position: relative;
    border: 3px solid #222B88;
    border-radius: 15px;
}
.login-input-section input {
    outline: none;
    border: none;
    width: 100%;
    padding: 1%;
    font-size: 18px;
}
.login-email-section {
    padding: 17px 0 18px 17px; 
    margin-bottom: 61px;
}

.login-password-section{ 
    padding: 4%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 42px; 
}


.email,.pass  {
    position: absolute;
    background-color: #fff;
    color: #222B88;
    top: -17px;
    z-index: 10000;
    padding: 2%;
}

.span {
    position: absolute;
    color: #ff0000;
    display: flex;
    margin-top: 73px;
    margin-left: 2px;
}
.top-span {
    color: #ff0000;
    display: flex;
    margin-top: -20px;
    margin-bottom: 20px;
    width: 350px;
}

.login-btn-primary {
    background: #162082 !important;
    padding: 2% 12%;
    border-radius: 10px;
    color: #FFFFFF;
    border: none; 
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    cursor: pointer;
    margin: 64px 0 41px 0;
}

.toggle {
    display: flex;
    align-items: center;
    width: 400px;
    color: green;
}

.toggle p{
    margin-left: 10px;
    color: #162082;
}

.toggle .active {
    box-shadow: none;
    background: none;
}
