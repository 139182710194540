.settlement-bills-table-content table {
    font-family: arial, sans-serif;
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;
    padding: 10px 80px;
  }
  .datepicker-class {
    width: 200px;
  }
  .bill-history-date-section {
    height: 56px;
    width: 180px;
    border: 1px solid rgba(106, 97, 111, 0.2);
    padding: 13px 0 10px 0px;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
  }
.bills-history-cont {
    width: 95%;
    height: 100px;
    margin: 40px 70px;
}

.bills-history-card {
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.bills-kscont {
    display: flex;
    align-items: center;
    margin-left: 800px;
    margin-top: 0px;
    position: absolute;
}
.bill-search-cont {
    width: 98%;
    display: flex;
    justify-content: flex-end;
    height: 60px;
    margin-top: 30px;
    /* margin: 0 auto; */
}
.bills-kscont img {
    width: 20px;
    position: absolute;
    right: 8px;
    top: 25px;
}

.settlement-verify-pass {
    width: 39%;
    color: #6A616F;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    float: right
}

.settlemet-search-section1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-left: 20px;
    padding: 20px 6px;
    /* width: 00px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    padding: 20px 6px;
    width: 100%; */
}

/* .settlement-input-cont button {
    width: 151px;
    height: 46px;
    margin: 0px 10px;
    background: #162082;
    color: #FFFFFF;
    outline: none;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 400;
} */
.bill-history-button {
    width: 151px;
    height: 46px;
    margin: 0px 10px;
    background: #162082;
    color: #FFFFFF;
    outline: none;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 400;
}

.settlement-input-cont input {
    text-transform: uppercase;
    /* width: 144px; */
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-left: 20px;
    padding: 10px 6px;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.settlement-input-cont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.settlement-bills-item-tr {
    background: #dddff6;
 }
 
 .settlement-bills-table-content{
    width: 83vw;
    max-height: 43vh;
    overflow-y: auto;
    margin: auto;
}
.settlement-bills-table-content td{
    text-align: left;
    padding-left: 20px;
}
.settlement-bills-table-content th{
    text-align: left;
    padding-left: 20px;
}


/* .billcont h2 {
    text-align: center;
    font-weight: 200;
}
.billtable {
    width: 80%;
    height: 900px;
    background-color: aquamarine;
    margin: 0 auto;
} */
/* 
.newtable {
    width: 100%;
    background-color: red;
    margin-top: 90px;
}
.vendor-container{
    display: flex;
}
.vendor-wrap{
    width: 100%;
}

.vendor-compts{
    width: 100%;
    height: 40%;
}
.vendor-table {
    width: 100%;
    height: 150%;
} */


/* .vcard {
    width: 357px;
    height: 94px;
    border-radius: 10.4386px;
    border: 1.04386px solid #462C94;
    background: #FFFFFF;
    box-shadow: 0px 12.5263px 29.2281px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
} */
/* .vcard p{
    width: 200px;
}
.vcard p:hover{
    font-weight: bold;
}
.vcard:hover {
    background-color: #DDDFF7;
    box-shadow: 0px 8.20513px 19.1453px #A8D5F2;
}
.active {
    background-color: #DDDFF7;
    box-shadow: 0px 8.20513px 19.1453px #A8D5F2;
}
.bb{
    font-weight: bold;
}
.search-section {
    width: 700px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    padding: 20px 6px;
    width: 100%;
}
.search-section2 {
    width: 700px;
    height: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-left: 20px;
    padding: 20px 6px;
    width: 100%;
    padding-right: 30px;
}
.kscont {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    width: 261px;
    height: 40px;
}
.kscont img{
    margin-right: 8px;
}
.search-section h2 {
    color: #162082;
    font-size: 24px;
    font-weight: 600;
}

.vtable-wrapper {
    width: 100%;
    height: 200px;
    padding: 20px;
}
.vtable-titlee {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 0px;
    padding-right: 130px;
    background-color: #ffffff;
}
.vtable-titlee2 {
    display: flex;
    width: 85%;
    justify-content: space-between;
    align-items: center;
    padding-left: 80px;
    background-color: #FFFFFF;
}
.l1 {
    margin-left: 100px;
}
.vtable-titlee button {
    width: 73px;
    height: 33px;
    background-color: #162082;
    border: none;
    outline: none;
    border-radius: 10px;
    color: white;
    font-weight: 700;
}
.vtable-titlee2 button {
    width: 73px;
    height: 33px;
    background-color: #162082;
    border: none;
    outline: none;
    border-radius: 10px;
    color: white;
    font-weight: 700;
}
.vtablelist {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 22px;
    align-items: center;
}
.vtablelist button {
     width: 33px;
    height: 33px;
    background-color: #162082;
    border: none;
    outline: none;
    border-radius: 100%;
    color: white;
    font-weight: 700;
    cursor: pointer;
}
.oneside {
    display: flex;
    justify-content: space-between;
    width: 300px;
}
.oneside p{
    width: 150px;
}
.green{
    color: green;
}
.red {
    color: red;
} */