.double_cover{
    width: 800px;
    height: 466px;
    border-radius: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    position: relative;
    margin: 40px auto;
    background-color: rgb(255, 255, 255);
}
.double_cover img {
    position: absolute;
    top: 5px;
    right: 15px;
    cursor: pointer;
}
.comparehead{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.comparehead p {
    font-weight: bold;
    color: #162082;
}
.comparebody {
    width: 100%;
    height: 370px;
    display: flex;
    justify-content: space-around;
}
.cimg{
    width: 45%;
    background-color: white;
    height: 100%;
}
.coampare_base{
    width: 80%;
    height: 30px;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-left: 40px;
}
.coampare_base img{
    cursor: pointer;
}
.coampare_base p {
    width: 100%;
}
.coampare_base2{
    width: 100%;
    height: 30px;
    background-color: rgb(226, 232, 232);
    display: flex;
    justify-content: space-between;
    position: relative;
}
.coampare_base img{
    position: absolute;
    cursor: pointer;
}
.coampare_base2 img{
    position: absolute;
}
.coampare_base p{
    font-weight: bold;
}
.comparee_selfie img{
    max-width: 100%;   
}
.comparee_selfie{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 300px;
}
.imgcompareholder{
    max-width: 100%;
    height: 100%;
    position: relative;
}
.imgcompareholder img{
    position: absolute;
    width: 80%;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    height: 80%;
}
.comparee_idcard{
    max-width: 100%;
    height: 100%;
    position: relative;
}
.comparee_idcard img{
    width: 80%;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    width: 30px;
}

/*Image view css*/
.image_view_cont{
    width: 689px;
    height: 604px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
}
.image_view_cont img{
    cursor: pointer;
}
.image_view_cont img {
    position: absolute;
    top:15px;
    right: 25px;
}
.image_view_cont p{
    font-weight: bold;
    text-align: center;
    position: absolute;
    top: 10px;
}
.innerBox{
    width: 780px;
    height: 504px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.innerBox_btn{
    width: 100%;
    height: 50px;
    background-color: bisque;
}
.view-image-btngroup{
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 10px;
}
.comparebtn {
    width: 177px;
    background-color: rgb(255, 255, 255);
    height: 60px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #162082;
    cursor: pointer;
    padding: 0 30px;
}
.comparebtn:active {
    background-color: rgb(214, 214, 214);
}
.comparebtn h6{
    color: #162082;
    font-size: 15px;
}
.imgfcont {
    width: 34px;
    height: 34px;
    position: relative;
}
.imgfcont img{
    position: absolute;
    top: -8px;
    right: 4px;
    width: 45px;
}
.comparebox {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    overflow:scroll;
}
.comparebox img{
    position: absolute;
    width: 100%;
    height: auto;
    left: 2px;
    cursor: zoom-in;
    pointer-events: none;
}
.zoom1{
    height: 450px;
    width: 120%;
}
.zoom2{
    height: 480px;
    width: 140%;
}
.zoom3{
    height: 490px;
    width: 180%;
}

.moved0{
    transform: rotate(0deg);
    animation: rotate-keyframes 0s;
}
.moved1{
    transform: rotate(90deg);
    animation: rotate-keyframes 0s;
}
.moved2{
    transform: rotate(180deg);
    animation: rotate-keyframes 0s;
}
.movedd3{
    transform: rotate(2700deg);
    animation: rotate-keyframes 0s;
}.movedd0{
    transform: rotate(0deg);
    animation: rotate-keyframes 0s;
}
.movedd1{
    transform: rotate(90deg);
    animation: rotate-keyframes 0s;
}
.movedd2{
    transform: rotate(180deg);
    animation: rotate-keyframes 0s;
}
.movedd3{
    transform: rotate(2700deg);
    animation: rotate-keyframes 0s;
}
@keyframes rotate-keyframes {
    from {
     transform: rotate(0deg);
    }
   
    to {
     transform: rotate(90deg);
    }
    
   }