.uControl-login-container {
    /* position: relative; */
    background: #fff;
    width: 678px;
    max-height: 787px;
    margin: 10px auto;
    border-radius: 20px;
    z-index: 1
}
.error-message {
    margin-left: 150px;
    margin-bottom: 50px;
}
.u-control-table {
    width: 90%;
    border-collapse: separate !important;
    border-spacing: 0 10px;
}
.u-control-table th {
    text-align: center;
}
.user-login-item-tr {
    border-radius: 218px !important;
    background-color: #dddff6;
    margin-top: -5px;
    text-align: center;
}
.uControl-login-details {
    align-items: center;
    margin-left: 0px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #462C94;
}

.cancel-icon6 {
    margin-left: 630px;
    align-items: center;
    margin-top: -20px;
    cursor: pointer;
}

.uControl-table-content{
    width: 100%;
    margin: 0px 30px;
    overflow: scroll;
    height: 570px;
}

.uControl-login-header {
    display: flex;
    justify-content: center;
}

.close-details {
    display: none;
}
