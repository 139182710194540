.issues-container{
    display: flex;
    flex-direction: column;
    color: #21205C;
    padding: 42px 46px 42px 40px;
    border-radius: 28px;
}
.issues-total-section{
    margin-bottom: 38px;
}
.issues-detail span{
    font-weight: 600;
    font-size: 17px;
}
.issues-detail h3{
    
    margin-top: 23px;
    font-weight: 600;
    font-size: 26px;

}
.issues-sub-section{
    display: flex;
    justify-content: space-between;
}
