  
  .profile-deactivate {
      position: relative;
      display: block;
      width: 476px;
      height: 389px;      
      margin: 129px auto auto;
      padding: 8px;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      border-radius: 15px;
      background-color: #fff;
    }
     
    .profile-deactivate-image {
      margin-top: 23px;
      margin-right: auto;
      margin-left: 194px;
    }
    
    .profile-deactivate-image-2 {
      position: absolute;
      left: auto;
      top: 3%;
      right: 2%;
      bottom: auto;
      cursor: pointer;
    }
    
    .close {
        display: none;
    }
    
    .profile-deactivate-flexedcont {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    
    .profile-deactivate-button {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 171px;
      height: 46px;
      margin-right: auto;
      margin-left: auto;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-decoration: none;
      border-radius: 9px;
      background-color: #FC3131;
      border: none;
    }
  
    .profile-deactivate-w-button {
        color: #fff;
    }
  
    .profile-deactivate-bold-text {
      position: absolute;
      left: auto;
      top: auto;
      right: 0%;
      bottom: 0%;
      margin-right: 35px;
      margin-bottom: 30px;
      color: #162082;
      font-size: 14.76px;
      cursor: pointer;
    }
    
    .profile-deactivate-text-block-2 {
      font-size: 17px;
      text-align: center;
      line-height: 25px;
      color: #6A616F;
    }
  
    .profile-deactivate-text {
        border-radius: 10px !important;
        padding: 10px !important;
        background: #FFFFFF !important;
        border: 1px solid rgba(106, 97, 111, 0.2) !important;
        line-height: 14px;
    }

    