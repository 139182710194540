.transaction-insight-container{
    padding:0 !important;
    display: flex;
    align-items: center;
    border-radius: 15px 15px 0px 0px;
    color: #162082;
}
.transaction-insight-legends{
    margin-bottom: -15px;
}
.transaction-details-section{
    margin: 14px 0 0 30px;
}

.transaction-details-section h4{
    padding: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    margin-bottom: 26px;
}
.transaction-details-section p{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: fit-content;
    
}
.transaction-select{
    display: flex;
    align-items: center;
    text-align: center;
    
    margin-bottom: 20px !important;
}
.transaction-select span p{
    margin: 0 !important;
    margin-left: 6px !important;
    font-weight: 600;
    font-size: 14px;
}

.transaction-insight-legend{
    display: flex;
    align-items: center;
    margin-bottom: 40.5px;
}

.transaction-insight-legend p{
    margin-bottom: -1px;
}
.transaction-insight-legend span{
    border-radius: 100%;
    height:23px ;
    width:23px ;
    margin-right: 5px;
}
.transaction-chart-section{
    width: 53%;
    margin-right: 5px;
}