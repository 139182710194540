.level1Dropdown-wrapper {
    position: absolute;
    z-index: 2000;
    margin-left: 700px;
    border-radius: 15px;
    /* margin-top: -10px; */
}
.rejectDropdown-modal{
    background: #ffffff;
    width: 300px;
    /* height: 220px; */
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.rejectDropdown-modal-content{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #162082;
    padding: 5px;
    margin-left: 5px;
}
