nav {
  background: transparent;
  background-image: linear-gradient(180deg, #9cf 0, #fc9);
  display: flex;
  justify-content: center;
  width: 160px;
  height: 100vh;
}
.deem {
  background-color: rgb(240, 237, 237);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.side-nav-img-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
  height: 268px;
  background-color: white;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 7.5px 7.5px 100px 100px;
}
.side-nav-img-section img {
  margin-top: 20px;
  margin-bottom: 15px;
}
.nav-name {
  width: fit-content;
  margin-bottom: 7px;
  text-align: center !important;
}

.side-nav-img-section p {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: justify;
  letter-spacing: -0.5px;
  color: #162082;
}
.verifier-p {
  background: #162082;
  border-radius: 40px;
  color: #fff;
  max-width: 100px;
  max-height: 40px;
  justify-content: center;
  text-align: center;
  /* width: auto; */
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-role {
  font-size: 13px;
  font-weight: 700;
  padding: 4px;
  word-wrap: inherit;
}

.side-nav-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 135px;
  background: linear-gradient(
    137.43deg,
    rgba(255, 255, 255, 0.5) 3.89%,
    rgba(255, 255, 255, 0.2) 100%
  );
  margin-top: 13px;
  margin-bottom: 13px;
  border-radius: 7.5px;
}
.side-nav-list {
  width: 110px;
  margin: 0 0 17px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  height: fit-content;
  align-items: center;
}

/* li:first-child, li:last-child{
    border: none !important;
} */

.side-nav-list li:nth-child(3),
.side-nav-list li:nth-child(4),
.side-nav-list li:nth-child(5) {
  border-bottom: 0.2px solid #162082;
}

.side-nav-list li:nth-child(2) {
  border-top: 0.2px solid #162082;
  border-bottom: 0.2px solid #162082;
}

.nav-icon-active {
  display: flex;
  flex-direction: column;
  background: #efefef;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: fit-content;
  padding: 3px;
  border-radius: 3px;
}
.nav-icon-active p {
  padding: 0 !important;
  margin: 0 !important;
  width: fit-content;
  font-weight: bold;
  font-size: 11px;
  color: #162082;
}
.side-nav-list li {
  width: 100px;
  height: 73px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.side-nav-list-active-dashboard li {
  background: #efefef;
  border-radius: 10px;
  border: none;
}

.side-nav-list li:first-child,
.side-nav-list li:last-child {
  border: none;
}

.side-nav-list li:first-child:hover::after {
  position: absolute;
  content: "Dashboard";
  width: 178px;
  height: 48px !important;
  margin-left: 250px;
  background: #162082;
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  font-size: 24px;
  background: #162082;
  border-radius: 0px 10px 10px 0px;
  transition: ease-in-out 5s;
}

.side-nav-list li:nth-child(2):hover::before {
  position: absolute;
  content: "KYC";
  width: 178px;
  height: 48px !important;
  margin-left: 250px;
  background: #162082;
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  font-size: 24px;
  background: #162082;
  border-radius: 0px 10px 10px 0px;
  transition: ease-in-out 5s;
}
.side-nav-list li:nth-child(3):hover::before {
  position: absolute;
  content: "Support";
  width: 178px;
  height: 48px !important;
  margin-left: 250px;
  background: #162082;
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  font-size: 24px;
  background: #162082;
  border-radius: 0px 10px 10px 0px;
  transition: ease-in-out 5s;
}
.side-nav-list li:nth-child(4):hover::before {
  position: absolute;
  content: "Settlement";
  width: 178px;
  height: 48px !important;
  margin-left: 250px;
  background: #162082;
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  font-size: 24px;
  background: #162082;
  border-radius: 0px 10px 10px 0px;
  transition: ease-in-out 5s;
}
.side-nav-list li:nth-child(5):hover::before {
  position: absolute;
  content: "Users";
  width: 178px;
  height: 48px !important;
  margin-left: 250px;
  background: #162082;
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  font-size: 24px;
  background: #162082;
  border-radius: 0px 10px 10px 0px;
  transition: ease-in-out 5s;
}
.side-nav-list li:nth-child(6):hover::before {
  position: absolute;
  content: "App Content";
  width: 178px;
  height: 48px !important;
  margin-left: 250px;
  background: #162082;
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  font-size: 24px;
  background: #162082;
  border-radius: 0px 10px 10px 0px;
  transition: ease-in-out 5s;
}
.side-nav button {
  background-color: #d10505;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  height: 49px;
  margin-bottom: 5px;
  width: 110px;
}
.right-content {
  width: calc(100% - 224px);
  margin-left: 40px;
  margin-right: 20px;
  height: 100vh;
}
.right-content-2 {
  width: calc(100% - 224px);
  margin-left: 40px;
  margin-right: 20px;
}
