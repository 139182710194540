.kyc-top-container {
    /* height: 70px; */
    /* padding: 10px; */
    width: 100%;
    border-bottom: 1px solid rgba(22, 32, 130,0.3);
    margin-top: 10px;
    margin-bottom: 10px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-left: 20px; */
}

.pending-verification {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-left: 30px;
    color: #162082;
}

.kyc-top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: #162082;
    font-weight: 600;
}
