.faq-table-content table {
    font-family: arial, sans-serif;
    border-collapse: separate;
    border-spacing: 0 7px;
    width: 100%;
    padding: 0px 80px;
    margin-left: 20px;
  }
  .faq-table-content {
      height: 40vh;
      overflow-y: scroll;
  }
  
.faq-table-content td {
    text-align: left;
    padding-left: 30px;
}
.faq-cont {
    width: 70%;
    height: 50px;
    margin: 70px auto 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-kscont img {
    width: 20px;
    position: absolute;
    right: 5px;
    top: 30px;
}

.faq-kscont {
    align-items: center;
    margin-left: 650px;
    margin-bottom: 60px;
    position: absolute;
}
.user-kscont img {
    width: 20px;
    position: absolute;
    right: 8px;
    top: 38px;
}

.faq-item-tr {
    background: #dddff6;
 }

 .faq-btn {
   width: 33px;
   height: 33px;
   background-color: #162082;
   border: none;
   outline: none;
   border-radius: 100%;
   color: white;
   font-weight: 700;
   cursor: pointer;
}
 
 .faq-btn-primary {
    display: flex;
    align-items: center;
    padding: 0px 30px;
    margin-left: -73px;
    border: none;
    background: #162082;
    color: #fff;
    border-radius: 10px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
 }

 .user-btn-primary img {
     padding-right: 10px;
 }