.kyc-cont1 {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    margin-left: 80px;
}
.kyc-level1-pagination-bar {
    width: 90%;
}
.kyc-cont {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    margin-left: 110px;
}
.kyc-search-cont {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 60px;
    margin: 0 auto;
}
.ktable {
    width: 90%;
    margin: 0 auto;
    height: 30px;
    border-radius: 5px;
  
}
.sdrop {
    width: 100px;
    height: 30px;
    display: flex;
    margin-left: 30px;
}
.sdrop label {
    margin-right: 5px;
}
.custom-select select {
    width: 40px;
    outline: none;
    border: none;
    background-color: #bac4fc;
}
li p {
    color: #fff;
}
.theading {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding-left: 50px;
}
.kbtn {
    background: #162082;
    border-radius: 5px;
    width: 73px;
    height: 33px;
    margin-right: -15px;
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
}
.kyc2-kbtn {
    background: #162082;
    border-radius: 5px;
    width: 73px;
    height: 37px;
    margin-right: -15px;
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
    border: none;
}
.kbtn2 {
    background: #162082;
    border-radius: 5px;
    width: 73px;
    height: 33px;
    margin-right: -20px;
}
ul {
    width: 100%;
    height: 60px;
    padding-top: 20px;
    background-color:#bac4fc;
    margin-bottom: 30px;
    padding-bottom: 55px;
}
ul li{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 100px;
}
.kscont {
    display: flex;
    align-items: center;
    margin-top: -30px;
    /* margin-bottom: 20px; */
    position: relative;
}
.kscont img {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 18px;
}
.kyc-table-cont{
    margin-left: -20px;
    max-height: 48vh;
    overflow-y: scroll;
}
.kyc-item-tr{
    background: #7780DE;
    /* opacity: 0.25; */
    border-radius: 5px;
}
.kyc-level-table {
    /* border-collapse: collapse; */
    border-collapse: separate;
    border-spacing: 0 .5em;
    margin: auto auto;
    font-size: 0.9em;
    min-width: 400px;
    width: 90%;
}
.kyc-level-table th {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #162082;
}
.kyc-table-tr{
    border-radius: 218px !important;
    background: #dddff6;
}
.kyc4-content1-modal{
    position: fixed;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 19000;
    padding: 25px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
