.gen-cont {
    display: flex;
}
.side2{
    width: 60px;
    height: 100vh;
}
.card-cont {
    display: flex;
    justify-content: space-between;
    width: 85%;
    padding-top: 40px;
}
.fix {
    margin-left: 100px;
}
.settlement-card-cont-2 {
    display: flex;
    justify-content: space-around;
    width: 90%;
    padding-top: 40px;
    margin-left: 50px;
}
.card-2 {
    width: 307px;
    height: 94px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    border: 0.683761px solid #462C94;
    border-radius: 6.83761px;
    cursor: pointer;
}

.card {
    width: 307px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 010px;
    border: 0.683761px solid #462C94;
    border-radius: 6.83761px;
    cursor: pointer;
}
.record-box{
    margin-top: 30px;
}
.card:hover {
    background-color: #DDDFF7;
    box-shadow: 0px 8.20513px 19.1453px #A8D5F2;
}
.record-box h2 {
    color: black;
    font-weight: 600;
    font-size: 32px;
}

.active {
    background-color: #DDDFF7;
    box-shadow: 0px 8.20513px 19.1453px #A8D5F2;
    font-weight: 700;
    color: #162082;
    font-size: 18px;
}
.active p {
    color: #162082;
}

.display-deactivate-control {
    position: absolute;
    margin-left: -420px;
    margin-top: -250px;
  }
