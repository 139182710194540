.agency-container{
    display: flex;;
}
.agency-vcont {
    display: flex;
    margin-left: 80px;
    margin-top: 40px;

}
.view-kyc-top{
    width: 90%;
    margin-left: 20px;
}
.agency-control {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.agency-t1{
    margin-bottom: 15px;
}
.agency-t1 p{
    width: 200px;
}
.agency-txthouse {
    width: 90%;
}
.agency-picright {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    width: 446px;
}
.agency-kyc-view-submit-btn{
    width: 172px;
    height: 46px;
    margin-top: -908px;
    margin-left: 250px;
    background: #162082;
    border-radius: 10px;
    border: none;
    color: white;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14.2644px;
}
.agency-kyc-view-compare-btn{
    width: 172px;
    height: 46px;
    background: #162082;
    border-radius: 10px;
    border: none;
    color: white;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14.2644px;
}
.agency-kyc-view-approved{
    width: 100px;
    height: 32px;
    margin-right: 43px;
    background: #089E17;
    border-radius: 10px;
    color: white;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14.2644px;
    border: none;
}
.agency-kyc-view-cancel-btn{
    width: 32px;
    height: 32px;
    margin-right: 15px;
    color: white;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14.2644px;
    background: #FC3131;
    border-radius: 10px;
    border: none;
}
.agency-kyc-view-rejected{
    width: 100px;
    height: 32px;
    margin-right: 3px;
    background: #FC3131;
    border-radius: 10px;
    color: white;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14.2644px;
    border: none;
}
.agency-up {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-right: 30px;
}
.agency-up img{
    margin-left: 10px;
}
.agency-upbox {
    width: 162px;
}
.agency-chk {
    display: flex;
    justify-content: space-between;
}
.agency-chk input{
    margin-top: -16px;
    margin-left: 2px;
}
.agency-chk2 {
    margin-top: 10px;
    padding:0 5px;
    display: flex;
    justify-content: space-between;
}
.agency-down {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.agency-chk3 {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.agency-chk p {
   width: 100%;
}
.agency-compare-img{
   margin-left: 50px;
}
.agency-picright img {
   width: 142px;
}
.agency-downbox {
    margin-bottom: 50px;
}
.agency-downbox img {
   width: 180px;
}
/* .agency-img img {
   margin-left: -60px;
} */
.agency-img p {
   /* margin-left: -60px; */
   width: 150px;
}
.agency-t1 h2 {
   font-size: 1.2rem;
   color: #162082;
   font-weight: 400;
}