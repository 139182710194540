.comments-container{
    width: 628px;
    height: 750px;
    margin: 20px auto;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(191, 191, 191);
    border-radius: 10px;
    padding: 15px;
}
/* .comments-modal-container{
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    overflow: hidden;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
} */
.comment-block-section{
    height: 450px;
    overflow: auto;
}
.supports-comment {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    overflow: hidden;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
}

.height2 {
    height: 700px;
}

.comments-container input{
    width: 560px;
    margin: 0 auto;
    height: 149px;
    background: rgba(106, 97, 111, 0.1);
    border-radius: 10px;
    border: none;
    outline: none;
    padding-left: 20px;
}
.comments-container .comment-btnhold {
    height: 60px;
    width: 336px;
    margin-top: 30px;
    margin-left: 230px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.comments-container .comment-btnhold button{
    background: #162082;
    border-radius: 10px;
    width: 168px;
    height: 56px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}
.comments-container .comment-btnhold button:nth-child(2){
    background-color: white;
    border: none;
    outline: none;
    color: #162082;
    width: 120px;
}
.comments-top{
    height: 44px;
    border-bottom: 1px solid rgb(224, 224, 224);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    color: rgb(61, 60, 60);
}
.comments-top img{
    cursor: pointer;
}
.commentblock {
    height: 150px;
    border-bottom: 1px solid rgb(200, 198, 198);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 8px;
}
.commentblock:nth-child(4){
    border-bottom: none;
}
.commentblock p{
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    font-size: 20px;
}
.commentblock .innerr {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}
.commentblock .innerr h5 {
    font-size: 1rem;
    color: rgb(61, 60, 60);
}
.commentblock .innerr span {
    font-size: 12px;
    color: rgb(61, 60, 60);
}