.transaction-history-container{
    position: fixed;
    padding-top: 25px;
    background-color: rgba(0, 0, 0, .2);
    height: 100vh;
    width: 100vw;
    z-index: 1000;
}
.transaction-history-content{
    padding: 0 25px;
    background-color: white;
    width: 900px;
    padding-bottom: 15px;
    margin: auto;
}
.transaction-history-summary{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.transaction-history-summary div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.transaction-history-summary div:nth-child(2){
    margin-right: 86px;
    margin-left: 86px;

}
.transaction-history-summary h1{
    color: #162082;
    font-weight: 600;
    font-size: 40px;
}
.transaction-history-summary p{
    width: fit-content;
    color: #462C94;
    font-size: 18px;
}

.transaction-history-search{
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
}
.transaction-history-button-section{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.transaction-history-close{
    width: 176px;
    height: 46px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14.2644px;
    line-height: 17px;
    text-align: center;
    background: #162082;
    border-radius: 8.91525px;
    color: #FFFFFF;
    margin-top: 13px ;
    margin-bottom: 13px ;
    border: none;
}