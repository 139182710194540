.kyc-distr-detail{
    display: flex;
    justify-content: space-between;
}
#kyc-column {
    --color: linear-gradient(180deg, #9FA6E7 0%, #162082 100%);
    height: 250px;
    width: 300px;
}
.demo-column {
    margin-top: -10px;
}
/* #kyc-column tr{
    margin-left: 5px;
}  */
#kyc-column th{
    font-weight: 200;
    font-size: 12px;
    align-items: center;
} 
#kyc-column span{
   color: #ffff;
   font-weight: 700;
   font-size: 18px;
}
