.modbox {
    position: relative;
    display: block;
    width: 395px;
    height: 312px;
    margin: 129px auto auto;
    padding: 8px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    border-radius: 15px;
    background-color: #fff;
  }
  
  .body {
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    background-color: #ddd3d3;
  }
  
  .closebox {
    position: static;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background-color: #162082;
  }
  
  .image {
    margin-top: 23px;
    margin-right: auto;
    margin-left: 154px;
  }
  
  .image-2 {
    position: absolute;
    left: auto;
    top: 3%;
    right: 2%;
    bottom: auto;
    cursor: pointer;
  }
  
  .close {
      display: none;
  }
  .text-block {
    position: relative;
    left: 10%;
    top: auto;
    right: 0%;
    bottom: 32%;
    font-family: 'Droid Sans', sans-serif;
  }
  
  .flexedcont {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .close-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 171px;
    height: 46px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 9px;
    background-color: #162082;
    text-decoration: none;
  }
  .w-button {
      color: #fff;
  }
  .bold-text {
    position: absolute;
    left: auto;
    top: auto;
    right: 0%;
    bottom: 0%;
    margin-right: 35px;
    margin-bottom: 43px;
    color: #162082;
    font-size: 14.76px;
    cursor: pointer;
  }
  
  .text-block-2 {
    font-size: 17px;
    text-align: center;
    line-height: 25px;
  }