.upload-modal {
    background-color: #ffffff;
    width: 650px;
    margin: 100px auto;
    height: 600px;
    border-radius: 10px;
    padding: 10px;
    display: block;
    z-index: 5;
}
.close-modal {
    display: none;
}
.vsn {
    width: 100%;
    margin-top: 380px;
    margin-left: 400px;
}
.upload-header {
    display: flex;
    width: 600px;
    justify-content: space-between;
    margin-left: 10px;
}
.header-right{
    font-weight: 600;
    font-size: 18px;
    color: #6A616F;
    line-height: 28px;
}
.uplaod-body {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 50px;
}
.body-1{
    width: 254px;
}
.body-11 {
    border: 1px solid #FC3131;
    border-radius: 5px;
    height: 254px;
    margin-top: 10px;
}
.body-2 {
    width: 254px;
}
.body-22 {
    border: 1px solid #FC3131;
    border-radius: 5px;
    height: 254px;
    margin-top: 10px;
}

.upload-footer {
    margin-left: 30%;
    margin-top: 70px;
}
.footer-button {
    background: #162082;
    border-radius: 10px;
    padding: 10px;
    color: white;
    font-weight: 700;
    font-size: 18px;
    width: 125px;
    text-decoration: none;
}

.footer-button-2 {
    background: none;
    border: none;
    padding: 10px;
    color: #162082;
    font-weight: 700;
    font-size: 18px;
    width: 125px;
    text-decoration: none;
}
.umCont {
    padding-top: 30px;
    width: 100vw;
    height: 110vh;
    overflow-y: hidden;
    z-index: 1200;
}

.upload-container {
    width: 1809px;
    height: 1300px;
    align-items: center;
    position: fixed;
    /* left: 0; */
    background: rgba(0, 0, 0, 0.3);
    /* overflow: hidden; */
}