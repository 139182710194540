.chat-wrap {
  height: 100vh !important;
  height: min-content;
}
.vcardd {
  width: 317px;
  height: 170px;
  border-radius: 10.4386px;
  border: 1.04386px solid #462c94;
  box-shadow: 0px 12.5263px 29.2281px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  cursor: pointer;
  margin-top: 87px;
  padding-left: 40px;
}
.vcardd2 {
  width: 317px;
  height: 94px;
  border-radius: 10.4386px;
  border: 1.04386px solid #462c94;
  box-shadow: 0px 12.5263px 29.2281px rgb(0 0 0 / 10%);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 87px;
  padding: 30px 20px;
}
.support-renderTable {
  /* width: 95%; */
}
.vcardd2 p {
  width: 80%;
}
.vcspace2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vcspace2 p {
  width: 150px;
}
.vcspace2 h2 {
  font-size: 2.5rem;
}
.vcardd p {
  width: 150px;
}
.chat-wrapper {
  width: 90%;
  margin-top: -50px;
  height: 95%;
  margin-left: 30px;
  overflow-y: scroll;
  
}


.live-chat {
  margin-left: 15px;
  /* width: 100%; */
}
.live-chat-escalate-b {
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 19000;
  padding: 25px 0;
  display: flex;
  justify-content: space-around;
  height: 100vh;
}
.collapse-cont {
  width: 100%;
  border-radius: 10px;
  margin-top: 200px;
  margin-left: auto;
  position: relative;
  overflow: hidden;
  max-height: 289px;
  transition: max-height 0.15s ease-out;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}
.ccheight {
  max-height: 89px;
  transition: max-height 0.15s ease-out;
}
.toggleBtn {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #162082;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toptog {
  width: 80%;
  height: 60px;
  margin-left: 45px;
  margin-top: 20px;
}
.toptog h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6a616f;
  line-height: 28px;
  margin-top: -20px;
}
.rotate {
  transform: rotate(-90deg);
}
.rotatei {
  transform: rotate(90deg);
}
.midddletog h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #6a616f;
}
.midddletog {
  width: 70%;
  margin-left: 45px;
  height: 90px;
  /* background-color: rgb(125, 178, 225); */
  position: absolute;
  top: 0;
  opacity: 0.8;
  left: 230px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
}
.ml {
  width: 205px;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: aliceblue;
}
.mdl {
  display: none;
}
.ml p {
  width: 100%;
  color: rgb(0, 0, 0);
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #6a616f;
}
.ml h2,
p {
  color: rgb(87, 86, 86);
}
.toptog h2 {
  margin-right: 40px;
  color: rgb(87, 86, 86);
}
.toptog {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.gbtns > button {
  width: 110px;
  color: rgb(87, 86, 86);
  margin-right: 28px;
  height: 40px;
  border-radius: 10px;
  font-weight: bold;
  outline: none;
  background-color: white;
  border: 0.5px solid grey;
}
.gbtns > button:hover {
  background-color: rgb(143, 132, 212);
  color: white;
}
.gbtns-cont {
  display: none;
}
.downtog {
  width: 76%;
  height: 180px;
  /* background-color: blue; */
  margin-top: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
}
.dt {
  width: 205px;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: aliceblue;
}
.dt p {
  width: 100%;
  text-align: center;
}
.dt h2,
p {
  color: rgb(87, 86, 86);
}
.house-all {
  width: 100%;
  height: 700px;
  margin: 33px auto;
  display: flex;
  justify-content: flex-start;
  background-color: rgb(255, 255, 255);
}
.victor {
  width: 325px;
  height: 500px;
  background-color: white;
}
.kbtn {
  padding: 10px 30px;
  background: #162082;
  color: #fff;
  border-radius: 10px;
  text-decoration: none;
  font-weight: bold;
}
.uploadModalCont {
  position: absolute;
  top: -70px;
  left: 0;
}

.chat-vendor-card {
  width: 75%;
  height: 130px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  align-items: center;
  padding-left: 15px;
  /* padding-right: 35px; */
}
.chat-vendor-card2 {
  width: 80%;
  height: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  align-items: center;
  padding-left: 15px;
  /* padding-right: 55px; */
}
