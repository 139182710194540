/* body {
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    background-color: #ddd3d3;
  } */
  
  .profile-reactivate {
      position: relative;
      display: block;
      width: 395px;
      height: 428px;      
      margin: 129px auto auto;
      padding: 8px;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      border-radius: 15px;
      background-color: #fff;
    }
     
    .profile-reactivate-image {
      margin-top: 23px;
      margin-right: auto;
      margin-left: 164px;
    }
    
    .profile-reactivate-image-2 {
      position: absolute;
      left: auto;
      top: 3%;
      right: 2%;
      bottom: auto;
      cursor: pointer;
    }
    
    .close {
        display: none;
    }
    
    .profile-reactivate-flexedcont {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    
    .profile-reactivate-button {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 181px;
      height: 53px;
      margin-right: auto;
      margin-left: auto;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-decoration: none;
      border-radius: 9px;
      background-color: #162082;
    }
  
    .profile-reactivate-w-button {
        color: #fff;
    }
  
    .profile-reactivate-bold-text {
      position: absolute;
      left: auto;
      top: auto;
      right: 0%;
      bottom: 0%;
      margin-right: 35px;
      margin-bottom: 48px;
      color: #162082;
      font-size: 14.76px;
      cursor: pointer;
    }
    
    .profile-reactivate-text-block-2 {
      font-size: 17px;
      text-align: center;
      line-height: 25px;
      padding: 0 60px;
      color: #6A616F;
    }
  
    .profile-reactivate-textarea {
        border-radius: 10px;
        padding: 13px;
        background: #FFFFFF;
        border: 1px solid rgba(106, 97, 111, 0.2);
        line-height: 17px;
    }