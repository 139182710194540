.address-verification-container{
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    overflow: hidden;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.address-verification-view-container {
    /* position: relative; */
    background: #ffffff;
    width: 850px;
    height: 98%;
    margin: 10px auto 10px auto;
    padding: 10px;
    border-radius: 20px;
}

.address-verification {
    align-items: center;
    margin-left: 20px;
    margin-top: 30px;
    color: #162082;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
}

.cancel-icon {
    margin-left: 730px;
    align-items: center;
    margin-top: -20px;
}

.address-content {
    margin-bottom: 20px;
}

.address-header {
    display: flex;
    justify-content: center;
}

.address-verification-view-contents {
    display: flex;
    justify-content: space-evenly;
    margin: 80px 20px;
}

.address-content h5 {
    font-weight: 400;
    color: #462C94;
    font-size: 18px;
}

.address-content p{
    width: 100%;
    color: #6A616F;
    font-size: 18px;
}

.vendor-reassign-btn {
    width: 200px;
    height: 40px;
    background: #162082;
    border: none;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 14.2545px;
    box-shadow: 0px 2.37576px 7.12727px rgba(0, 0, 0, 0.1);
    border-radius: 5.93939px;
}
.vendor-mark-btn {
    width: 200px;
    height: 40px;
    background: #1DC642;
    border: none;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 14.2545px;
    box-shadow: 0px 2.37576px 7.12727px rgba(0, 0, 0, 0.1);
    border-radius: 5.93939px;
}
