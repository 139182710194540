.escalate-container{
    box-sizing: border-box;
    width: 812px;
    border-radius: 10px;
    padding: 31px 31px 0px 88px;
    background-color: white;
    /* height: 10%; */
    overflow: scroll;
}

.escalate-container1{
    /* box-sizing: border-box; */
    width: 812px;
    border-radius: 10px;
    padding: 31px 31px 0px 88px;
    background-color: white;
    /* height: 10%; */
    overflow: scroll;
}

.update-escalate-container{
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    overflow: hidden;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
}
.escalate-close{
    width: fit-content;
    float: right;
}
.escalate-container h6{
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    margin-bottom:17px ;
}
.escalate-container input[type="text"], .escalate-container select {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    width: 310px;
    padding: 15px;
    font-size: 18px;
    outline:  none;
}
.textarea{
    width: 583px;
    height: 180px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    padding: 15px;
}
.escalate-btn{
    width: 171px;
    padding: 13px 26px;
    background: #162082 !important;
    border-radius: 8px;
    color: #ffffff !important;
    margin-right: 24px;
}
.escalate-container button{
    background-color: #ffffff;
    font-weight: 700;
    font-size: 18px;
    border: none;
    cursor: pointer;
    color: #162082;
}
.escalate-checkbox{
    display: flex;
    align-items: center;
}
.escalate-container span {
    display: flex;
}
.escalate-checkbox input[type="radio"]{
    width: fit-content;
    margin-right: 13px;
}
.escalate-checkbox label{
    margin-right: 26px;
    font-weight: 400;
    font-size: 14px;
}