.login-history-button-container {
    background: #FFFFFF;
    border: 1px solid rgba(106, 97, 111, 0.5);
    box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 396px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-history-button-container button{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    border: none;
    background-color: transparent;
    color: #162082;
  }
  .login-history-button-1{
    height: 47px;
    width: 190px;
    border-radius: 10px;
  }
  .login-history-button-2{
    height: 47px;
    width: 200px;
    border-radius: 10px;
  }

.login-his-pro, .log-his-user{
    background: #7780DE !important;
    color: white !important;
    font-weight: 600 !important;
}
  