.kyc-cont {
    width: 93%;
    display: flex;
    flex-direction: column;
}
.search-cont {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 60px;
    /* margin: 0 auto; */
}
.failed-search-cont {
    width: 98%;
    display: flex;
    justify-content: flex-end;
    height: 60px;
    /* margin: 0 auto; */
}

.failed-kyc-cont {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    margin-left: 50px;
}
.kbtn {
   padding: 10px 20px;
    background: #162082;
    color: #fff;
    border-radius: 7px;
    text-decoration: none;
}
.tbody {
    background: #dddff6;
    border-radius: 10px;
}
.tbody td {
    padding: 10px;
}
.download-records {
    background-color: #162082;
    border-radius: 5px;
    border: none;
    color: #fff;
    width: 175px;
    padding: 5px;
    height: 40px;
    margin-right: 50px;
    cursor: pointer;
    margin-top: -70px;
}
.kscont {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}
/* .kscont img {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 18px;
} */
.settlement-failed-table-content{
    width: 75vw;
    max-height: 50vh;
    overflow-y: auto;
    margin: auto;
}
.transaction-table {
    /* margin-left: 50px; */
    /* width: 100%; */
    font-family: arial, sans-serif;
    border-collapse: separate;
    border-spacing: 0 5px;
    width: 94%;
    /* padding: 10px 80px; */
}
.transaction-table td {
    text-align: left;
    /* padding-left: 30px; */
}
.table-wrapper {
    width: 100%;
    max-height: 45vh;
    overflow-y: auto;
    /* margin: auto; */
}
.table-wrapper-2 {
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;
    /* margin: auto; */
}
.thead {
    background-color: #fff;
    font-weight: 0;
    font-size: 18px;
}
