.notfound-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100%;
    /* background-color: #f5f5f5; */
    flex-direction: column;
}
.notfound-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 42%;
    height: 60%;
    color: #162082;
    /* background-color: #f5f5f5; */
}

.notfound-content h6 {
    text-align: center;
    font-weight: 400;
    line-height: 28px;
    /* font-size: 17px; */
}

.notfound-container {
    margin-right: 30px;
    margin-left: 30px;
}

.top-container {
    /* height: 70px; */
    /* padding: 10px; */
    width: 100%;
    border-bottom: 1px solid rgba(22, 32, 130,0.3);
    margin-top: 10px;
    margin-bottom: 10px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-left: 20px; */
}
.top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: #162082;
    font-weight: 600;
}

.notfound-footer {
    width: Hug(277px);
    height: Hug(44px);
    border: solid 2px #162082;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.notfound-footer-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
