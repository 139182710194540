.level1View-kyctop{
    width: 90%;
    margin-bottom: 64px;
}
.level1View-content{
    display: flex;
    margin-top: 35px;
    height: 70vh;

}
.levelView-content1{
    margin-left: 60px;
    padding-right: 0px;
}
.levelView-content2{
    border-left: 1px solid #16218239;
    padding-left: 80px;
}
.content1-container {
    margin-top: 20px;
}
.content2-container {
    margin-top: 20px;
}
.content1-body1 {
    margin-bottom: 20px;
}
.content1-body1 h2{
    font-weight: 400;
    font-size: 18px;
    color: #462C94;
    width: 265px;
}
.content2-body2 h2{
    font-weight: 400;
    font-size: 12px;
    color: #462C94;
    width: 86px;
}
.content1-body1 p{
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #6A616F;
    width: 378px;
}
.content2-image{
    margin-top: 5px;
    margin-bottom: 53px;
    border-radius: 5px
}
.content2-footer{
    display: flex;
}
.content2-approve {
    background: #089E17;
    border-radius: 10px;
    width: 122px;
    height: 40px;
    padding: 5px;
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin-right: 32px;
    border: none;
}
.content2-reject {
    background: #FC3131;
    border-radius: 10px;
    width: 122px;
    height: 40px;
    padding: 5px;
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin-right: 32px;
    border: none;
}
.content1-modal{
    position: fixed;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 19000;
    padding: 25px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.hidden {
    display: none;
}
